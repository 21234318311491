import InteractiveTutorialStep from './interactive_tutorial_step'
import { createElement } from '../../../helpers/dom_helpers'
import DraggableModal from '../draggable_modal'
import getText from '../../../helpers/getText'

class ModalStep extends InteractiveTutorialStep {
  render() {
    super.render()
    this._renderModal()
    this._renderLabel()
    this._renderButton()
    this.modal().updatePosition(this.focusElementBoundingBox())
  }

  handleFocusElementPositionChange(focusElementBoundingBox) {
    super.handleFocusElementPositionChange()
    if (this._userHasMovedModal) { return }
    this._focusElementBoundingBox = focusElementBoundingBox
    this.modal().updatePosition(focusElementBoundingBox)
  }

  modal() {
    return this._modal
  }

  button() {
    return this._button
  }

  _onButtonClick(_event) {
    setTimeout(() => this.onComplete(), 700)
  }

  _labelText() {
    throw new Error('Subclass should override')
  }

  _renderModal() {
    this._modal = new DraggableModal(
      this.parentElement(),
      this.stepData().repositionEnabled,
      this.stepData().cb
    )
  }

  _renderLabel() {
    this._label = createElement(this.modal().element(), 'span')
    this._label.innerText = this._labelText()
  }

  _renderButton() {
    this._button = createElement(this.modal().element(), 'button', 'btn btn--primary margin-top-sm')
    this._button.innerText = getText.dom_data('modal-step', 'continue')
    this._button.style.float = 'right'
    this._button.onclick = this._onButtonClick.bind(this)
  }
}

export default ModalStep
