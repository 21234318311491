export default {
  dom_data (id, data) {
    let textToDisplay = 'Lacking text'
    let textElement = document.querySelector(`#${id}`)
    if (textElement) textToDisplay = textElement.dataset[data]
    return textToDisplay
  },

  step_name_value () {
    let step_name
    let instructions_element = document.querySelector('.tutorial-step b:first-child')
    if (instructions_element) step_name = instructions_element.innerHTML
    return step_name
  }
}
