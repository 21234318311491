import ModalStep from './modal_step'
import getText from '../../../helpers/getText'

class ViewStep extends ModalStep {
  _labelText() {
    return getText.dom_data('view', 'label')
  }
}

export default ViewStep
